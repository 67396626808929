import React, { useEffect, useState } from 'react'

const styles = require('./radioButtons.module.scss')

export default ({ field, setField }) => {
  const [value, setValue] = useState(undefined)
  const handleToggle = e => {
    setValue(e.target.value)
  }

  useEffect(() => {
    setField(field.id, value)
  }, [value])

  return (
    <div className={styles.field}>
      <label className={styles.question}>{field.label + `${field.settings && field.settings.required ? '*' : ''}`}</label>
      <div className={styles.options}>
        {field.options.map((option, i) => (
          <fieldset className={styles.fieldset} key={i} type="radio">
            <label className={styles.label}>{option}</label>
            <input className={styles.input} type="radio" id={option} name={field.id} value={option} onChange={handleToggle} />
          </fieldset>
        ))}
      </div>
    </div>
  )
}
