import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { graphql, useStaticQuery } from 'gatsby'

import { Footer, Header, Main } from './components'

import * as styles from './layout.module.scss'

const Layout = ({ children = null, title, description, metaTags = [], author = '', location, image = '', slug, components }) => {
  const [formOpen, setFormOpen] = useState(false)
  const [settings, setSettings] = useState(undefined)
  const [logoColor, setLogoColor] = useState('light')
  const [linksColor, setLinksColor] = useState('light')

  const { allSettingsJson } = useStaticQuery(graphql`
    {
      allSettingsJson(filter: { fields: { slug: { eq: "/settings/settings/" } } }) {
        nodes {
          siteInfo {
            author
            description
            metaTags
            title
            titleDivider
          }
        }
      }
    }
  `)

  useEffect(() => {
    setSettings(allSettingsJson.nodes[0].siteInfo)
  }, [allSettingsJson])

  const openForm = () => {
    setFormOpen(true)
  }
  const handleCloseForm = () => {
    setFormOpen(false)
  }
  return settings ? (
    <div className={styles.layout + `${formOpen ? ` ${styles.disabled}` : ''}`}>
      <Helmet>
        <html lang="en" />
        <title>{slug === '/' ? settings.title : `${title || '404: Page Not Found'}` + settings.titleDivider + settings.title}</title>
        <meta name="description" content={description || settings.description} />
        <meta name="google-site-verification" content="t6PE0U_AGG6uNH96jB_Marwnk5qbfF6UjjiDZhbz13s" />
        <meta name="keywords" content={metaTags ? metaTags.join(' ') : settings.metaTags.join(' ')} />
        <meta name="author" content={author || settings.author} />
        <meta property="og:title" content={slug === '/' ? settings.title : `${title || '404: Page Not Found'}` + settings.titleDivider + settings.title} />
        <meta property="og:description" content={description || settings.description} />
        <meta property="og:image" itemProp="image" content={image} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={location.href} />
        <meta property="twitter:title" content={slug === '/' ? settings.title : `${title || '404: Page Not Found'}` + settings.titleDivider + settings.title} />
        <meta property="twitter:description" content={description || settings.description} />
        <meta property="twitter:image" itemProp="image" content={image} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta property="twitter:card" content="summary_large_image" />
      </Helmet>
      <Header openForm={openForm} logoColor={logoColor} linksColor={linksColor} />
      <Main components={components} title={title} setLogoColor={setLogoColor} setLinksColor={setLinksColor}>
        {children}
      </Main>
      <Footer />
      <div className={styles.cover} onClick={handleCloseForm} />
    </div>
  ) : null
}

export default Layout
