import React, { useEffect, useState } from 'react'

import formFieldBlock from '../../'

const styles = require('./repeatableFields.module.scss')

export default ({ field, setField }) => {
  const [repeatableFieldGroupData, setRepeatableFieldGroupData] = useState([])

  const createNewFieldGroup = () => {
    const obj = {}
    field.fields.forEach(field => {
      obj[field.id] = undefined
    })
    setRepeatableFieldGroupData([...repeatableFieldGroupData, obj])
  }

  useEffect(() => {
    createNewFieldGroup()
  }, [])

  useEffect(() => {
    setField(field.id, repeatableFieldGroupData)
  }, [repeatableFieldGroupData])

  const handleAddNewRepeatableFieldGroupItem = e => {
    e.preventDefault()
    createNewFieldGroup()
  }

  return (
    <div className={styles.repeatableFieldGroup}>
      <h3 className={styles.label}>{field.label}</h3>
      {repeatableFieldGroupData.map((fieldGroup, i) => {
        const handleEditItem = (field, data) => {
          const array = [...repeatableFieldGroupData]
          array[i][field] = data
          setRepeatableFieldGroupData(array)
        }

        return (
          <div key={i} id={field.id + '-' + (i + 1)} className={styles.fieldGroup}>
            <h4 className={styles.itemLabel}>
              {field.singularName} {i + 1}
            </h4>
            {field.fields.map((field, j) => formFieldBlock(field, j, fieldGroup, handleEditItem))}
          </div>
        )
      })}
      <button className={styles.button} onClick={handleAddNewRepeatableFieldGroupItem}>
        {field.buttonLabel}
      </button>
    </div>
  )
}
