// extracted by mini-css-extract-plugin
export var section = "infographic-module--section--34utj";
export var backgroundImage = "infographic-module--background-image--3KNrq";
export var content = "infographic-module--content--FR44m";
export var title = "infographic-module--title--2M_Ji";
export var text = "infographic-module--text--KBFx_";
export var dots = "infographic-module--dots--3AS8Q";
export var infographic = "infographic-module--infographic--1uoQ6";
export var item = "infographic-module--item--18HD4";
export var figure = "infographic-module--figure--1NfN8";
export var description = "infographic-module--description--3HKEM";
export var active = "infographic-module--active--3F53l";