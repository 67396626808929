import React from 'react'
import { graphql } from 'gatsby'
import ReactPlayer from 'react-player'
import { Fade } from 'react-reveal'

import { BackgroundText, Button, LargeText } from '../../../shared'

import * as styles from './hero.module.scss'

const Hero = ({ component: { background_text, size = 'large', button, display_button, image, image_or_video, logo, text, video, links_color, mobile = false } }) => {
  return (
    <section className={styles.section + `${mobile ? ` ${styles.mobile}` : ''}`} logocolor={logo} linkscolor={links_color}>
      <div className={styles.body}>
        <Fade left>
          <LargeText text={text} size={size} />
        </Fade>
        <Button className={styles.desktopButton} display_button={display_button} button={button} color="white" />
      </div>
      {image_or_video === 'image' ? (
        <div className={styles.image + ` background-image-cover-center`} style={{ backgroundImage: `url(${image})` }}>
          <BackgroundText className={styles.desktopBackgroundText} text={background_text} />
          <BackgroundText className={styles.mobileBackgroundText} align="right" text={background_text} />
          <Button className={styles.mobileButton} display_button={display_button} button={button} color="white" />
        </div>
      ) : image_or_video === 'video' && video ? (
        <div className={styles.video}>
          <BackgroundText className={styles.desktopBackgroundText} text={background_text} />
          <BackgroundText className={styles.mobileBackgroundText} align="right" text={background_text} />
          <div className={styles.videoContainer}>
            <ReactPlayer url={video} playing muted loop controls={false} width="108.54vw" height="61vw" config={{ wistia: { options: { autoPlay: true, fitStrategy: 'cover', endVideoBehavior: 'loop' } } }} />
          </div>
          <div className={styles.mobileVideoContainer}>
            <ReactPlayer url={video} playing muted loop controls={false} width="195.56vw" height="110vw" config={{ wistia: { options: { autoPlay: true, fitStrategy: 'cover', endVideoBehavior: 'loop' } } }} />
          </div>
          <Button className={styles.mobileButton} display_button={display_button} button={button} color="white" />
        </div>
      ) : null}
    </section>
  )
}

export const query = graphql`
  fragment Hero on PagesJson {
    components {
      text
      display_button
      button {
        label
        button_type
        function
        page
        url
      }
      image_or_video
      image
      video
      background_text
    }
  }
`

export default Hero
