import React, { useEffect, useState } from 'react'
import { graphql, navigate, useStaticQuery } from 'gatsby'
import { Fade } from 'react-reveal'

import * as styles from './button.module.scss'

const Button = ({ display_button, button, color = 'white', className = '' }) => {
  const [page, setPage] = useState(undefined)

  const {
    allPagesJson: { nodes }
  } = useStaticQuery(graphql`
    {
      allPagesJson {
        nodes {
          fields {
            link
            path
          }
        }
      }
    }
  `)

  useEffect(() => {
    if (button && button.page && button.button_type && nodes && nodes.length && button.button_type === 'Page') {
      setPage(nodes.map(node => ({ path: node.fields.path.substr(0, node.fields.path.length - 1) + '.json', link: node.fields.link })).find(node => button.page.includes(node.path)))
    }
  }, [button, nodes])

  const handleClick = e => {
    if (button && button.button_type && button.button_type === 'Page' && page && page.link) {
      navigate(page.link)
    } else if (button && button.button_type && button.button_type === 'URL' && button.url) {
      Object.assign(document.createElement('a'), {
        target: '_blank',
        href: button.url
      }).click()
    } else if (button && button.button_type && button.button_type === 'Function' && button.function) {
      if (button.function === 'Scroll Down') {
        const element = e.target.parentElement.parentElement.parentElement
        window.scrollTo({
          top: element.offsetHeight,
          behavior: 'smooth'
        })
      }
    }
    return null
  }

  return (
    <div className={styles.buttonContainer + `${className ? ` ${className}` : ''}`}>
      {display_button ? (
        <Fade up>
          <button onClick={handleClick} className={styles.button + ` ${styles[color]}`}>
            <p className={styles.label}>{button.label}</p>
            <div className={styles.arrow + ' background-image-contain-center'} />
          </button>
        </Fade>
      ) : null}
    </div>
  )
}

export default Button
