import React, { useCallback, useEffect, useState, useRef } from 'react'
import SignatureCanvas from 'react-signature-canvas'

const styles = require('./signature.module.scss')

export default ({ field, setField }) => {
  const [value, setValue] = useState(undefined)

  const sigCanvas = useRef()
  const sigPad = useRef()

  const ref = useCallback(node => {
    if (node !== undefined) {
      sigCanvas.current = node?.getCanvas()
      sigPad.current = node?.getSignaturePad()
    }
  }, [])

  const handleSetSignature = () => {
    sigCanvas.current.toBlob(blob => {
      setValue(blob)
    })
  }

  const handleClear = e => {
    e.preventDefault()
    sigPad.current.clear()
    setValue(undefined)
  }

  useEffect(() => {
    setField(field.id, value)
  }, [value])

  return (
    <div className={styles.signature}>
      <p className={styles.question}>{field.name + `${field.settings && field.settings.required ? '*' : ''}`}</p>
      <div className={styles.container}>
        <SignatureCanvas ref={ref} onEnd={handleSetSignature} canvasProps={{ className: styles.canvas }} />
      </div>
      <button className={styles.button + `${!value ? ` ${styles.disabled}` : ''}`} onClick={handleClear}>
        Clear
      </button>
    </div>
  )
}
