import React from 'react'
import parse from 'html-react-parser'

const styles = require('./richText.module.scss')

export default ({ field }) => (
  <div className={styles.richText}>
    {field.content && parse(field.content)}
  </div>
)
