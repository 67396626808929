import React, { useEffect, useState } from 'react'

const styles = require('./checkboxes.module.scss')

export default ({ field, setField }) => {
  const [value, setValue] = useState([])

  useEffect(() => {
    setField(field.id, value.join(', '))
  }, [value])

  return (
    <div className={styles.field}>
      <label className={styles.question}>{field.label + `${field.settings && field.settings.required ? '*' : ''}`}</label>
      <div className={styles.options}>
        {field.options.map((option, i) => {
          const handleToggle = e => {
            e.target.checked ? setValue([...value, option]) : setValue(value.filter(val => val !== option))
          }
          return (
            <fieldset className={styles.fieldset} key={i} type="checkbox">
              <label className={styles.label} htmlFor={option}>
                {option}
              </label>
              <input className={styles.input} type="checkbox" name={option} id={option} onClick={handleToggle} />
            </fieldset>
          )
        })}
      </div>
    </div>
  )
}
