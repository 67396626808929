import React from 'react'
import { Fade } from 'react-reveal'

import * as styles from './largeText.module.scss'

const LargeText = ({ text, size = 'large' }) => (
  <div className={styles.textContainer}>
    <Fade up>
      <h1 className={styles.text + `${size === 'small' ? ` ${styles.small}` : ''}`}>
        {text.split('\n').map((line, i) => (
          <span key={i}>{line}</span>
        ))}
      </h1>
    </Fade>
  </div>
)

export default LargeText
