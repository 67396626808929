import React, { useEffect, useState } from 'react'

import formFieldBlock from './formFieldBlock/'

import * as styles from './form.module.scss'

const Form = ({ form }) => {
  const [formData, setFormData] = useState({ id: form.id })
  const [data, setData] = useState(new window.FormData())
  const [complete, setComplete] = useState(false)

  useEffect(() => {
    const form = new window.FormData()

    Object.entries(formData).forEach(entry => {
      // console.log'entry', typeof entry[1], entry)
      if (typeof entry[1] === 'string') {
        if (entry[0] && entry[1]) {
          form.set(entry[0], entry[1])
        }
      } else if (Array.isArray(entry[1])) {
        entry[1].forEach((arrayItem, i) => {
          Object.entries(arrayItem).forEach(arrayItemEntry => {
            form.set(entry[0] + '_' + (i + 1) + '_' + arrayItemEntry[0], arrayItemEntry[1])
          })
        })
      } else if (typeof entry[1] === 'object') {
        Object.entries(entry[1]).forEach(subentry => {
          // console.log'subentry', typeof subentry[1], subentry)
          if (typeof subentry[1] === 'string') {
            if (subentry[0] && subentry[1]) {
              form.set(entry[0] + '_' + subentry[0], subentry[1])
            }
          } else if (Array.isArray(subentry[1])) {
            subentry[1].forEach((subarrayitem, i) => {
              Object.entries(subarrayitem).forEach(subArrayItemEntry => {
                form.set(subentry[0] + '_' + (i + 1) + '_' + subArrayItemEntry[0], subArrayItemEntry[1])
              })
            })
          } else if (subentry[1] && (('File' in window && subentry[1] instanceof File) || ('Blob' in window && subentry[1] instanceof Blob))) {
            form.set(entry[0] + '_' + subentry[0], subentry[1])
          } else if (typeof subentry[1] === 'object') {
            // console.log'nested object')
          } else {
            // console.log'subentry catch', subentry)
            form.set(entry[0] + '_' + subentry[0], subentry[1])
          }
        })
      } else {
        form.set(entry[0], entry[1])
      }
    })
    // console.log'RESET')
    for (const entry of form.entries()) {
      // console.logentry[0] + ': ' + entry[1])
    }
    setData(form)
  }, [formData])

  useEffect(() => {
    setFormData({ id: form.id })
  }, [form])

  const setField = (field, data) => {
    setFormData({ ...formData, [field]: data })
  }

  const handleSubmitForm = e => {
    e.preventDefault()
    window
      .fetch(form.postUrl, {
        method: 'POST',
        mode: 'no-cors',
        cache: 'no-cache',
        body: data
      })
      .then(() => {
        setComplete(true)
      })
  }

  return formData ? (
    <div className={styles.form}>
      {complete ? (
        <div className={styles.success}>
          <p>Thank you for your submission.</p>
        </div>
      ) : (
        <form className={styles.form} id={form.id} name={form.name} onSubmit={handleSubmitForm} noValidate>
          {form.fields.map((field, i) => formFieldBlock(field, i, formData, setField))}
          <button className={styles.button} style={{ display: 'grid', gridAutoFlow: 'column' }}>
            <p className={styles.label}>Submit</p>
            <div className={styles.arrow} />
          </button>
        </form>
      )}
    </div>
  ) : null
}

export default Form
