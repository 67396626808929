import React, { useEffect, useState } from 'react'
import { graphql, useStaticQuery } from 'gatsby'

import { SiteLink } from '../../../shared'

import * as styles from './links.module.scss'

const Links = () => {
  const [links, setLinks] = useState([])
  const { allSettingsJson } = useStaticQuery(graphql`
    {
      allSettingsJson(filter: { fields: { slug: { eq: "/settings/settings/" } } }) {
        nodes {
          footer {
            links {
              label
              page
            }
          }
        }
      }
    }
  `)

  useEffect(() => {
    setLinks(allSettingsJson.nodes[0].footer.links)
  }, [allSettingsJson])

  return (
    <section className={styles.section}>
      {links.map((link, i) => (
        <SiteLink key={i} className={styles.link} activeClassName={styles.active} page={link}>
          <p className={styles.label}>{link.label}</p>
        </SiteLink>
      ))}
    </section>
  )
}

export default Links
