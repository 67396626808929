// extracted by mini-css-extract-plugin
export var section = "contentTabs-module--section--K4VJS";
export var mobileGradient = "contentTabs-module--mobile-gradient--3DUD7";
export var desktopGradient = "contentTabs-module--desktop-gradient--21w5J";
export var tabs = "contentTabs-module--tabs--3DpZi";
export var tab = "contentTabs-module--tab--3w93W";
export var line = "contentTabs-module--line--2ufcC";
export var button = "contentTabs-module--button--3j0OS";
export var icon = "contentTabs-module--icon--2kdn6";
export var label = "contentTabs-module--label--s4TuN";
export var active = "contentTabs-module--active--6nbXT";
export var contentContainer = "contentTabs-module--content-container--2Us2M";
export var content = "contentTabs-module--content--uN0I1";
export var title = "contentTabs-module--title--FHlJg";
export var text = "contentTabs-module--text--rwKKl";
export var buttonContainer = "contentTabs-module--button-container--1fA2f";