import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { SiteLink } from '../../../shared'

import * as styles from './privacy.module.scss'

const Privacy = () => {
  const {
    settingsJson: {
      footer: { privacy, privacy_page, display_privacy_link }
    }
  } = useStaticQuery(graphql`
    {
      settingsJson(fields: { slug: { eq: "/settings/settings/" } }) {
        footer {
          privacy
          privacy_page
          display_privacy_link
        }
      }
    }
  `)
  return display_privacy_link ? (
    <SiteLink className={styles.link} activeClassName={null} page={{ page: privacy_page }}>
      {privacy}
    </SiteLink>
  ) : (
    <div />
  )
}

export default Privacy
