// extracted by mini-css-extract-plugin
export var section = "team-module--section--3CstO";
export var mobile = "team-module--mobile--3rQTK";
export var container = "team-module--container--4ZCI0";
export var title = "team-module--title--F_31_";
export var viewToggles = "team-module--view-toggles--3eyOs";
export var button = "team-module--button--1-RfF";
export var list = "team-module--list--3PwY4";
export var grid = "team-module--grid--30iDp";
export var active = "team-module--active--217v6";
export var gridContainer = "team-module--grid-container--3r6Rl";
export var listItems = "team-module--list-items--2R2a7";
export var gridItems = "team-module--grid-items--jkkdO";
export var member = "team-module--member--3ayFZ";
export var photo = "team-module--photo--2_7rk";
export var gradient = "team-module--gradient--eAcuX";
export var listContainer = "team-module--list-container--1K-xO";
export var name = "team-module--name--loCYL";
export var detail = "team-module--detail--1d4DO";
export var background = "team-module--background--1QrXY";
export var teamMemberName = "team-module--team-member-name--218v_";
export var teamMemberTitle = "team-module--team-member-title--3UIn8";
export var select = "team-module--select--3MbPo";