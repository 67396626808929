import React, { useEffect, useState } from 'react'
import { graphql, useStaticQuery } from 'gatsby'

import * as styles from './contact.module.scss'
import { SiteLink } from '../../../shared'

const Contact = () => {
  const {
    settingsJson: {
      footer: { address, contact_page, phone }
    }
  } = useStaticQuery(graphql`
    {
      settingsJson(fields: { slug: { eq: "/settings/settings/" } }) {
        footer {
          address
          phone
          contact_page
        }
      }
    }
  `)

  return (
    <section className={styles.section}>
      {address && <p className={styles.address}>{address}</p>}
      {phone && (
        <a className={styles.phone} href={`tel:+1${phone}`}>
          ({phone.slice(0, 3)}) {phone.slice(3, 6)}-{phone.slice(6, 10)}
        </a>
      )}
      {contact_page && (
        <SiteLink className={styles.link + ` ${styles.contactLink}`} activeClassName={null} page={{ page: contact_page }}>
          Contact Us
        </SiteLink>
      )}
      {phone && (
        <a className={styles.link} href={`https://www.google.com/maps/place/${encodeURIComponent(address)}`} target="_blank" rel="noopener noreferrer">
          Get directions
        </a>
      )}
    </section>
  )
}

export default Contact
