import React, { useEffect, useState } from 'react'

const styles = require('./text.module.scss')

export default ({ field, setField }) => {
  const [value, setValue] = useState('')

  const handleSetValue = e => {
    setValue(e.target.value)
  }

  useEffect(() => {
    setField(field.id, value)
  }, [value])

  return (
    <fieldset className={styles.fieldset}>
      <label className={styles.label} htmlFor={field.id}>
        {field.label + `${field.settings && field.settings.required ? '*' : ''}`}
      </label>
      <input className={styles.input} id={field.id} name={field.id} placeholder={field.label} onChange={handleSetValue} required={field.settings && field.settings.required} value={value} />
    </fieldset>
  )
}
