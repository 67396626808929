import React, { useEffect, useState } from 'react'

const styles = require('./date.module.scss')

export default ({ field, setField }) => {
  const [value, setValue] = useState('')

  const handleDateChange = e => {
    setValue(e.target.value)
  }

  useEffect(() => {
    setField(field.id, value)
  }, [value])

  return (
    <div className={styles.field}>
      <label className={styles.label} htmlFor={field.id}>{field.label + `${field.settings && field.settings.required ? '*' : ''}`}</label>
      <input className={styles.input} type='date' name={field.id} id={field.id} required={field.settings && field.settings.required} onChange={handleDateChange} value={value} />
    </div>
  )
}
