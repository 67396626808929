// extracted by mini-css-extract-plugin
export var section = "testimonials-module--section--3rDQJ";
export var list = "testimonials-module--list--3BiUa";
export var label = "testimonials-module--label--2Q5qE";
export var title = "testimonials-module--title--1hEY7";
export var testimonials = "testimonials-module--testimonials--1WyWA";
export var testimonial = "testimonials-module--testimonial--lFwZe";
export var name = "testimonials-module--name--ZFmaS";
export var dot = "testimonials-module--dot--I6WcY";
export var active = "testimonials-module--active--3MNxR";
export var mobileDetail = "testimonials-module--mobile-detail--IQgsF";
export var mobileAuthor = "testimonials-module--mobile-author--1Cbi9";
export var detail = "testimonials-module--detail--2yifw";
export var quote = "testimonials-module--quote--3gg1R";
export var line = "testimonials-module--line--XwHKQ";
export var gradient = "testimonials-module--gradient--jh2he";