import React, { useState } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { Fade } from 'react-reveal'

import * as styles from './team.module.scss'
import { GradientBackground } from '../../../shared'

const Team = ({ component: { title, logo, links_color } }) => {
  const [active, setActive] = useState(0)
  const [mode, setMode] = useState('grid')

  const {
    teamJson: { team_members }
  } = useStaticQuery(graphql`
    {
      teamJson {
        team_members {
          name
          photo
          title_department
        }
      }
    }
  `)

  const handleSetListMode = () => {
    setMode('list')
  }

  const handleSetGridMode = () => {
    setMode('grid')
  }

  const handleChange = e => {
    setActive(e.target.value)
  }

  return (
    <section className={styles.section} logocolor={logo} linkscolor={links_color}>
      <div className={styles.container + `${mode === 'grid' ? ` ${styles.gridContainer}` : ` ${styles.listContainer}`}`}>
        <Fade up>
          <h2 className={styles.title}>{title}</h2>
        </Fade>
        <Fade up cascade>
          <div className={styles.viewToggles}>
            <button className={styles.button} onClick={handleSetListMode}>
              <div className={styles.list + `${mode === 'list' ? ` ${styles.active}` : ''}`} />
            </button>
            <button className={styles.button} onClick={handleSetGridMode}>
              <div className={styles.grid + `${mode === 'grid' ? ` ${styles.active}` : ''}`} />
            </button>
          </div>
        </Fade>
        <Fade up cascade>
          <div className={styles.listItems}>
            {team_members && team_members.length
              ? team_members.map((member, i) => {
                  const handleSetActive = () => {
                    setActive(i)
                  }
                  return (
                    <div className={styles.member} key={i}>
                      <button className={styles.name} onClick={handleSetActive}>
                        {member.name}
                      </button>
                    </div>
                  )
                })
              : null}
          </div>
        </Fade>
        <Fade up cascade>
          <div className={styles.gridItems}>
            {team_members && team_members.length
              ? team_members.map((member, i) => {
                  const handleSetActive = () => {
                    setActive(i)
                  }
                  return (
                    <div className={styles.member} key={i}>
                      <button className={styles.button + `${active === i ? ` ${styles.active}` : ''}`} onClick={handleSetActive}>
                        <div className={styles.photo + ' background-image-cover-center'} style={{ backgroundImage: `url(${member.photo})` }} />
                        <GradientBackground className={styles.gradient} transparentEdge="topHalf" />
                      </button>
                    </div>
                  )
                })
              : null}
          </div>
        </Fade>
      </div>
      <div className={styles.mobile}>
        <Fade up>
          <h2 className={styles.title}>{title}</h2>
        </Fade>
        <Fade up>
          <select className={styles.select} onChange={handleChange}>
            {team_members &&
              team_members.length &&
              team_members.map((member, i) => {
                return (
                  <option key={i} value={i}>
                    {member.name}
                  </option>
                )
              })}
          </select>
        </Fade>
      </div>
      <div className={styles.detail}>
        {team_members && team_members.length && team_members[active] ? (
          <>
            <div className={styles.background + ' background-image-cover-center'} style={{ backgroundImage: `url(${team_members[active].photo})` }}></div>
            <GradientBackground className={styles.gradient} transparentEdge="top" />
            <Fade up>
              <h3 className={styles.teamMemberName}>{team_members[active].name}</h3>
            </Fade>
            <Fade up>
              <p className={styles.teamMemberTitle}>{team_members[active].title_department}</p>
            </Fade>
          </>
        ) : null}
      </div>
    </section>
  )
}

export const query = graphql`
  fragment Team on PagesJson {
    components {
      title
    }
  }
`

export default Team
