import React from 'react'
import { graphql } from 'gatsby'
import { Fade } from 'react-reveal'

import settings from '../../../../../content/settings/settings.json'

import * as styles from './careersContactForm.module.scss'
import { Form } from '../../../shared'

const CareersContactForm = ({ component: { title, text, logo, links_color } }) => (
  <section className={styles.section} logocolor={logo} linkscolor={links_color}>
    <Fade up>
      <h2 className={styles.title}>
        {title.split('\n').map((line, i) => (
          <span key={i}>{line}</span>
        ))}
      </h2>
    </Fade>
    <Fade up>
      <p className={styles.text}>
        {text.split('\n').map((line, i) => (
          <span key={i}>{line}</span>
        ))}
      </p>
    </Fade>
    <Fade up>
      <div className={styles.formContainer}>
        <Form form={settings['careers_form']} />
      </div>
    </Fade>
  </section>
)

export const query = graphql`
  fragment CareersContactForm on PagesJson {
    components {
      title
      text
      label
    }
  }
`

export default CareersContactForm
