// extracted by mini-css-extract-plugin
export var section = "list-module--section--2yMO9";
export var title = "list-module--title--2GDTv";
export var text = "list-module--text--3iT_V";
export var viewToggles = "list-module--view-toggles--_A2gN";
export var button = "list-module--button--2AIPL";
export var list = "list-module--list--DTdY0";
export var grid = "list-module--grid--2BBQI";
export var active = "list-module--active--3NAju";
export var listContainer = "list-module--list-container--1Zqin";
export var capability = "list-module--capability--3zOp4";
export var name = "list-module--name--3tyke";
export var gridContainer = "list-module--grid-container--3j04z";
export var arrows = "list-module--arrows--1JhGW";
export var arrowRight = "list-module--arrow-right--3gD4k";
export var arrow = "list-module--arrow--LyqMx";
export var arrowLeft = "list-module--arrow-left--2oTsV";
export var hide = "list-module--hide--3Ikfh";
export var right = "list-module--right--CTWKR";
export var left = "list-module--left--3jY6r";
export var capabilityContainer = "list-module--capability-container--dsE0B";