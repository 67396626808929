import React from 'react'

import { Contact, Copyright, Links, Privacy, Social } from './components'

import * as styles from './footer.module.scss'

const Footer = () => {
  return (
    <footer id="site-footer" className={styles.footer}>
      <div className={styles.left}>
        <Links />
        <Copyright />
      </div>
      <div className={styles.right}>
        <Contact />
        <div className={styles.bottom}>
          <Privacy />
          <Social />
        </div>
      </div>
      <div className={styles.mobile}>
        <Contact />
        <Privacy />
        <Copyright />
      </div>
    </footer>
  )
}

export default Footer
