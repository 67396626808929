import React, { useEffect, useState } from 'react'
import { graphql, useStaticQuery } from 'gatsby'

import { Links as FooterLinks } from '../../../footer/components'
import { SiteLink } from '../../../shared'

import * as styles from './links.module.scss'

const Links = ({ openForm, linksColor }) => {
  const [links, setLinks] = useState([])
  const [menuOpen, setMenuOpen] = useState(false)

  const { allSettingsJson } = useStaticQuery(graphql`
    {
      allSettingsJson(filter: { fields: { slug: { eq: "/settings/settings/" } } }) {
        nodes {
          header {
            links {
              icon
              label
              page
              type
              url
            }
          }
        }
      }
    }
  `)

  const handleMenuButton = () => {
    setMenuOpen(!menuOpen)
  }

  useEffect(() => {
    setLinks(allSettingsJson.nodes[0].header.links)
  }, [allSettingsJson])

  return (
    <section className={styles.section + `${menuOpen ? ` ${styles.active}` : ''}`} onClick={handleMenuButton}>
      <div className={styles.links + ` ${styles[linksColor]}` + `${menuOpen ? ` ${styles.active}` : ''}`}>
        {links.map((link, i) =>
          link.type === 'Page' ? (
            <SiteLink key={i} className={styles.link} activeClassName={styles.active} page={link}>
              {link.icon && <div className={styles.icon} style={{ backgroundImage: `url(${link.icon})` }} />}
              {!link.icon ? <p className={styles.label}>{link.label}</p> : null}
            </SiteLink>
          ) : link.type === 'URL' ? (
            <a className={styles.link} key={i} href={link.url} target="_blank" rel="noopener noreferrer">
              {link.icon && <div className={styles.icon} style={{ backgroundImage: `url(${link.icon})` }} />}
            </a>
          ) : (
            <div className={styles.link} onClick={openForm} key={i}>
              {link.icon && <div className={styles.icon} style={{ backgroundImage: `url(${link.icon})` }} />}
              <p className={styles.label}>{link.label}</p>
            </div>
          )
        )}
        <div className={styles.footer}>
          <FooterLinks />
        </div>
      </div>
    </section>
  )
}

export default Links
