import Banner from './banner/banner'
import Careers from './careers/careers'
import CareersContactForm from './careersContactForm/careersContactForm'
import ContactForm from './contactForm/contactForm'
import ContactInfo from './contactInfo/contactInfo'
import ContentTabs from './contentTabs/contentTabs'
import Cta from './cta/cta'
import Hero from './hero/hero'
import Infographic from './infographic/infographic'
import List from './list/list'
import RichText from './richText/richText'
import Team from './team/team'
import Testimonials from './testimonials/testimonials'
import TextColumns from './textColumns/textColumns'
import TextTabs from './textTabs/textTabs'

export default {
  'main-banner': Banner,
  'main-careers': Careers,
  'main-careers-contact-form': CareersContactForm,
  'main-contact-form': ContactForm,
  'main-contact-info': ContactInfo,
  'main-content-tabs': ContentTabs,
  'main-cta': Cta,
  'main-hero': Hero,
  'main-infographic': Infographic,
  'main-list': List,
  'main-rich-text': RichText,
  'main-team': Team,
  'main-testimonials': Testimonials,
  'main-text-columns': TextColumns,
  'main-text-tabs': TextTabs
}
