import React, { useEffect, useState } from 'react'

import formFieldBlock from '../..'

const styles = require('./fieldGroup.module.scss')

export default ({ field, setField }) => {
  const [value, setValue] = useState(undefined)

  useEffect(() => {
    const obj = {}
    // field.fields.forEach(field => { obj[field.id] = undefined })
    setValue({ ...obj })
  }, [])

  useEffect(() => {
    setField(field.id, value)
  }, [value])

  const setFieldGroup = (field, data) => {
    setValue({ ...value, [field]: data })
  }

  return value ? (
    <div className={styles.fieldGroup + `${field.settings.hideFieldGroupLabel ? ` ${styles.custom}` : ''}`}>
      <h2 className={styles.title}>{field.label}</h2>
      {field.fields.map((field, i) => formFieldBlock(field, i, value, setFieldGroup))}
    </div>
  ) : null
}
