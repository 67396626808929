import React from 'react'

import Blocks from './components'

export default (field, key, value, setFieldFunction) => {
  const Block = Object.keys(Blocks).indexOf(field.template) !== null && Object.values(Blocks)[Object.keys(Blocks).indexOf(field.template)]

  if (Block && field && field.settings) {
    if (field.settings.dependentField && value && value[field.settings.dependentField] && value[field.settings.dependentField].length && JSON.stringify(value[field.settings.dependentField])) {
      // console.log(JSON.stringify(value[field.settings.dependentField]), `"${field.settings.dependentValue}"`)
    }
    if (field.settings.displayConditionally === false || (field.settings.dependentField && value && value[field.settings.dependentField] && value[field.settings.dependentField].includes(field.settings.dependentValue))) {
      return <Block key={key} field={field} setField={setFieldFunction} />
    }
    return null
  }
  return <p key={key}>{field.template} does not exist</p>
}
