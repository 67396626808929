import React, { useEffect, useState } from 'react'
import { Fade } from 'react-reveal'

import Components from './components'

import * as styles from './main.module.scss'

const Main = ({ components, children, title, setLogoColor, setLinksColor }) => {
  useEffect(() => {
    const topElement = () => {
      const sections = document.elementsFromPoint(2, 2).filter(el => el.tagName === 'SECTION')
      const section = sections && sections.length && sections[0]
      const logoColor = section && section.attributes.getNamedItem('logocolor')
      const linksColor = section && section.attributes.getNamedItem('linkscolor')
      logoColor ? setLogoColor(logoColor.value) : setLogoColor('light')
      linksColor ? setLinksColor(linksColor.value) : setLinksColor('light')
    }

    document.addEventListener('scroll', topElement)

    return () => document.removeEventListener('scroll', topElement)
  }, [])

  return (
    <main id="main" className={styles.main}>
      <div className={styles.components}>
        {components && components.length
          ? components.map((component, i) => {
              const index = Object.keys(Components).indexOf(component.template)
              const Component = index !== null && Object.values(Components)[index]
              return Component ? (
                <Fade key={i} up>
                  <Component key={i} component={component} title={title} />
                </Fade>
              ) : (
                <p key={i} className={styles.title}>
                  The <strong>{component.template}</strong> component does not exist
                </p>
              )
            })
          : null}
      </div>
      {children}
    </main>
  )
}

export default Main
