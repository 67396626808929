import React from 'react'
import { graphql, Link, useStaticQuery } from 'gatsby'
import { Fade } from 'react-reveal'

import * as styles from './careers.module.scss'

const Careers = ({ component: { text, logo, links_color } }) => {
  const {
    allCareersJson: { nodes: careers }
  } = useStaticQuery(graphql`
    {
      allCareersJson(sort: { fields: date, order: DESC }, filter: { enablePage: { eq: true } }) {
        nodes {
          title
          fields {
            slug
          }
        }
      }
    }
  `)

  return (
    <section className={styles.section} logocolor={logo} linkscolor={links_color}>
      <Fade up>
        <p className={styles.text}>
          {text.split('\n').map((line, i) => (
            <span key={i}>{line}</span>
          ))}
        </p>
      </Fade>
      <Fade up cascade>
        <div className={styles.careers}>
          {careers && careers.length
            ? careers.map((career, i) => (
                <Link key={i} to={career.fields.slug} className={styles.career}>
                  {career.title}
                </Link>
              ))
            : null}
        </div>
      </Fade>
    </section>
  )
}

export const query = graphql`
  fragment Careers on PagesJson {
    components {
      text
    }
  }
`

export default Careers
