import React, { useState, useEffect } from 'react'
import { graphql } from 'gatsby'
import { Fade } from 'react-reveal'

import * as styles from './infographic.module.scss'
import { GradientBackground } from '../../../shared'

const Infographic = ({ component: { title, text, image, infographic_data, logo, links_color } }) => {
  const [active, setActive] = useState(0)

  return (
    <section className={styles.section} logocolor={logo} linkscolor={links_color}>
      <div className={styles.backgroundImage + ' background-image-cover-center'} style={{ backgroundImage: `url(${image})` }} />
      {/* <GradientBackground className={styles.gradient} transparentEdge="right" /> */}
      <GradientBackground className={styles.gradient} transparentEdge="right" />
      <div className={styles.content}>
        <Fade up>
          <h2 className={styles.title}>
            {title.split('\n').map((line, i) => (
              <span key={i}>{line}</span>
            ))}
          </h2>
        </Fade>
        <Fade up>
          <p className={styles.text}>
            {text.split('\n').map((line, i) => (
              <span key={i}>{line}</span>
            ))}
          </p>
        </Fade>
      </div>
      {infographic_data && infographic_data.length ? (
        <>
          <Fade left cascade>
            <div className={styles.infographic} id="infographic-component">
              {infographic_data.map((item, i) => (
                <div className={styles.item} key={i}>
                  <h3 className={styles.figure}>{item.figure}</h3>
                  <p className={styles.description}>{item.description}</p>
                </div>
              ))}
            </div>
          </Fade>
          <Fade left cascade>
            <div className={styles.dots}>
              {infographic_data.map((_item, i) => {
                const handleClick = () => {
                  setActive(i)
                  const list = document.getElementById('infographic-component')
                  if (list) {
                    list.scroll({ left: i * (list.scrollWidth / infographic_data.length), behavior: 'smooth' })
                  }
                }
                return <button onClick={handleClick} className={styles.item + `${active === i ? ` ${styles.active}` : ''}`} key={i} />
              })}
            </div>
          </Fade>
        </>
      ) : null}
    </section>
  )
}

export const query = graphql`
  fragment Infographic on PagesJson {
    components {
      title
      text
      image
      infographic_data {
        figure
        description
      }
    }
  }
`

export default Infographic
