import React from 'react'
import { graphql } from 'gatsby'
import { Fade } from 'react-reveal'

import { BackgroundText, Button } from '../../../shared'

import Hero from '../hero/hero'

import * as styles from './contactInfo.module.scss'

const ContactInfo = ({ component: { label, address, email, phone, button_label, image_overlay, logo, links_color, mobile_header_text } }) => (
  <>
    <section className={styles.section} logocolor={logo} linkscolor={links_color}>
      <div className={styles.infoContainer}>
        <div className={styles.info}>
          <Fade up>
            <h1 className={styles.label}>{label}</h1>
          </Fade>
          <Fade up>
            <a className={styles.address} href={`https://www.google.com/maps/place/${encodeURIComponent(address)}`} target="_blank" rel="noopener noreferrer">
              {address.split('\n').map((line, i) => (
                <span key={i}>{line}</span>
              ))}
            </a>
          </Fade>
          <Fade up>
            <a className={styles.email} href={`mailto:${email}`}>
              {email}
            </a>
          </Fade>
          <Fade up>
            <a className={styles.phone} href={`tel:${phone}`}>
              ({phone.slice(0, 3)}) {phone.slice(3, 6)}-{phone.slice(6, 10)}
            </a>
          </Fade>
          <Button display_button={true} button={{ label: button_label, button_type: 'Function', function: 'Scroll Down' }} color="green" />
        </div>
      </div>
      <div className={styles.image + ` background-image-cover-center`} style={{ backgroundImage: `url(${image_overlay.image})` }}>
        <BackgroundText text={image_overlay.text} />
      </div>
    </section>
    <Hero component={{ background_text: image_overlay.text, button: { label: button_label, button_type: 'Function', function: 'Scroll Down' }, display_button: true, image: image_overlay.image, image_or_video: 'image', logo, text: mobile_header_text, video: '', links_color, mobile: true }} />
  </>
)

export const query = graphql`
  fragment ContactInfo on PagesJson {
    components {
      label
      address
      email
      phone
      mobile_header_text
      button_label
      image_overlay {
        image
        text
      }
    }
  }
`

export default ContactInfo
