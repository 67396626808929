import React, { useEffect, useState } from 'react'
import { graphql, useStaticQuery } from 'gatsby'

import * as styles from './social.module.scss'

const Social = () => {
  const [socialLinks, setSocialLinks] = useState([])
  const { allSettingsJson } = useStaticQuery(graphql`
    {
      allSettingsJson(filter: { fields: { slug: { eq: "/settings/settings/" } } }) {
        nodes {
          footer {
            social {
              icon
              name
              url
            }
          }
        }
      }
    }
  `)

  useEffect(() => {
    setSocialLinks(allSettingsJson.nodes[0].footer.social)
  }, [allSettingsJson])

  return (
    <section className={styles.section}>
      {socialLinks.map((link, i) => (
        <a className={styles.link} key={i} href={link.url} target="_blank" rel="noopener noreferrer" style={{ backgroundImage: `url(${link.icon})` }} />
      ))}
    </section>
  )
}

export default Social
