// extracted by mini-css-extract-plugin
export var section = "textTabs-module--section--3m3fG";
export var title = "textTabs-module--title--3F_FY";
export var mobileTabs = "textTabs-module--mobile-tabs--3Nuwz";
export var tabs = "textTabs-module--tabs--12KtD";
export var tab = "textTabs-module--tab--uJ1Fc";
export var tabTitle = "textTabs-module--tab-title--2I_SY";
export var active = "textTabs-module--active--1kb2L";
export var content = "textTabs-module--content--2c1Xi";
export var tabContent = "textTabs-module--tab-content--2tPli";
export var form = "textTabs-module--form--1XfIh";
export var paragraph = "textTabs-module--paragraph--3VWo2";
export var formContainer = "textTabs-module--form-container--2b-sE";
export var background = "textTabs-module--background--5BuX3";
export var container = "textTabs-module--container--2HcAr";