import Checkboxes from './checkboxes'
import Date from './date'
import Divider from './divider'
import FieldGroup from './fieldGroup'
import FileUpload from './fileUpload'
import Heading from './heading'
import RadioButtons from './radioButtons'
import RepeatableFields from './repeatableFields'
import RichText from './richText'
import Select from './select'
import Signature from './signature'
import Text from './text'
import Textarea from './textarea'
import Toggle from './toggle'

export default {
  'form-field-checkboxes': Checkboxes,
  'form-field-date': Date,
  'form-field-file-upload': FileUpload,
  'form-field-radio-buttons': RadioButtons,
  'form-field-repeatable-fields': RepeatableFields,
  'form-field-select': Select,
  'form-field-signature': Signature,
  'form-field-text': Text,
  'form-field-text-area': Textarea,
  'form-field-toggle': Toggle,
  'form-divider': Divider,
  'form-field-group': FieldGroup,
  'form-heading': Heading,
  'form-rich-text': RichText
}
