import React, { useEffect, useState } from 'react'

const styles = require('./select.module.scss')

export default ({ field, setField }) => {
  const [value, setValue] = useState('')

  const handleSelect = e => {
    setValue(e.target.value)
  }

  useEffect(() => {
    setField(field.id, value)
  }, [value])

  return (
    <div className={styles.field}>
      <label className={styles.question} htmlFor={field.id}>{field.label}</label>
      <select className={styles.select} id={field.id} name={field.id} onChange={handleSelect} value={value}>
        {field.options.map((option, i) => (
          <option key={i} value={option}>{option}</option>
        ))}
      </select>
    </div>
  )
}
