import React, { useState } from 'react'
import { graphql } from 'gatsby'
import { Fade } from 'react-reveal'

import { Button, GradientBackground } from '../../../shared'

import * as styles from './contentTabs.module.scss'

const ContentTabs = ({ component: { image, tabs, logo, links_color, title, text, display_button, button } }) => (
  <section className={styles.section + ' background-image-cover-center'} style={{ backgroundImage: `url(${image})` }} logocolor={logo} linkscolor={links_color}>
    <GradientBackground className={styles.desktopGradient} transparentEdge="left" />
    <GradientBackground className={styles.mobileGradient} transparentEdge="bottom" />
    <Fade left cascade>
      <div className={styles.tabs}>
        {tabs.map((tab, i) => (
          <div key={i} className={styles.tab}>
            <div className={styles.line} />
            <div className={styles.button}>
              <div className={styles.icon + ' background-image-contain-center'} style={{ backgroundImage: `url(${tab.icon})` }} />
            </div>
            <p className={styles.label}>{tab.icon_label}</p>
          </div>
        ))}
      </div>
    </Fade>
    <div className={styles.contentContainer}>
      <Fade right cascade>
        <div className={styles.content}>
          {title ? (
            <h2 className={styles.title}>
              {title.split('\n').map((line, i) => (
                <span key={i}>{line}</span>
              ))}
            </h2>
          ) : null}
          {text ? (
            <p className={styles.text}>
              {text.split('\n').map((line, i) => (
                <span key={i}>{line}</span>
              ))}
            </p>
          ) : null}
          {display_button ? (
            <div className={styles.buttonContainer}>
              <Button display_button={display_button} button={button} />
            </div>
          ) : null}
        </div>
      </Fade>
    </div>
  </section>
)

export const query = graphql`
  fragment ContentTabs on PagesJson {
    components {
      image
      tabs {
        icon
        icon_label
      }
      title
      text
      display_button
      button {
        label
        button_type
        function
        page
        url
      }
    }
  }
`

export default ContentTabs
