// extracted by mini-css-extract-plugin
export var section = "hero-module--section--2aM2m";
export var body = "hero-module--body--3lXmj";
export var desktopButton = "hero-module--desktop-button--37IGq";
export var image = "hero-module--image--2CBA6";
export var video = "hero-module--video--zwmwr";
export var desktopBackgroundText = "hero-module--desktop-background-text--2rSE6";
export var mobileBackgroundText = "hero-module--mobile-background-text--2Ak_I";
export var mobileButton = "hero-module--mobile-button--dtTRt";
export var mobileVideoContainer = "hero-module--mobile-video-container--3LwGj";
export var videoContainer = "hero-module--video-container--3u6EZ";
export var mobile = "hero-module--mobile--bP-eS";