import React, { useState } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { Fade } from 'react-reveal'

import * as styles from './testimonials.module.scss'
import { GradientBackground } from '../../../shared'

const Testimonials = ({ component: { title, image, logo, links_color } }) => {
  const [active, setActive] = useState(0)

  const {
    testimonialsJson: { testimonials }
  } = useStaticQuery(graphql`
    {
      testimonialsJson(fields: { slug: { eq: "/testimonials/testimonials/" } }) {
        testimonials {
          name
          industry
          quote
        }
      }
    }
  `)

  return (
    <section className={styles.section} logocolor={logo} linkscolor={links_color}>
      <div className={styles.list}>
        <Fade up>
          <h2 className={styles.title}>{title}</h2>
        </Fade>
        <Fade up>
          <div className={styles.mobileDetail}>
            {testimonials[active].quote.split('\n').map((line, i) => (
              <p className={styles.line} key={i}>
                {i === 0 ? '"' : ''}
                {line.trim()}
                {i === testimonials[active].quote.split('\n').length - 1 ? '"' : ''}
              </p>
            ))}
          </div>
        </Fade>
        <Fade up>
          <div className={styles.mobileAuthor}>
            {testimonials && testimonials.length && testimonials[active] && testimonials[active].name ? (
              <p className={styles.name}>
                {testimonials[active].name} - {testimonials[active].industry}
              </p>
            ) : null}
          </div>
        </Fade>
        <Fade up cascade>
          <div className={styles.testimonials}>
            {testimonials && testimonials.length
              ? testimonials.map((testimonial, i) => {
                  const handleSetActive = () => {
                    setActive(i)
                  }
                  return (
                    <button key={i} onClick={handleSetActive} className={styles.testimonial + `${active === i ? ` ${styles.active}` : ''}`}>
                      <p className={styles.name}>
                        {testimonial.name} - {testimonial.industry}
                      </p>
                      <div className={styles.dot} />
                    </button>
                  )
                })
              : null}
          </div>
        </Fade>
        {testimonials && testimonials.length && testimonials[active] && testimonials[active].name ? <p className={styles.label}>{testimonials[active].name}</p> : null}
      </div>
      <div className={styles.detail + ' background-image-cover-center'} style={{ backgroundImage: `url(${image})` }}>
        {testimonials && testimonials.length && testimonials[active] && testimonials[active].quote ? (
          <Fade up>
            <div className={styles.quote}>
              {testimonials[active].quote.split('\n').map((line, i) => (
                <p className={styles.line} key={i}>
                  {i === 0 ? '"' : ''}
                  {line.trim()}
                  {i === testimonials[active].quote.split('\n').length - 1 ? '"' : ''}
                </p>
              ))}
            </div>
          </Fade>
        ) : null}
        <GradientBackground className={styles.gradient} transparentEdge="top" />
      </div>
    </section>
  )
}

export const query = graphql`
  fragment Testimonials on PagesJson {
    components {
      title
      image
    }
  }
`

export default Testimonials
