import React, { useEffect, useState } from 'react'
import { useDropzone } from 'react-dropzone'

const styles = require('./fileUpload.module.scss')

export default ({ field, setField }) => {
  const [value, setValue] = useState(null)
  const { getRootProps, getInputProps } = useDropzone({
    maxFiles: 1,
    onDrop: files => {
      if (files.length) {
        setValue(files[0])
      } else {
        setValue(null)
      }
    }
  })

  useEffect(() => {
    setField(field.id, value)
  }, [value])

  return (
    <fieldset contains="file" className={styles.field}>
      <label show="true" className={styles.label}>
        {field.label + `${field.settings && field.settings.required ? '*' : ''}`}
      </label>
      <div className={styles.uploadContainer} {...getRootProps()}>
        <p className={styles.message + `${value ? ` ${styles.filled}` : ''}`}>{!value ? 'Click or drag here to upload.' : value.name}</p>
        <input className={styles.input} name={field.id} id={field.id} required={field.settings && field.settings.required} {...getInputProps()} />
      </div>
    </fieldset>
  )
}
