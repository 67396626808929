import React from 'react'
import { graphql } from 'gatsby'

import * as styles from './banner.module.scss'
import { BackgroundText, GradientBackground, LargeText } from '../../../shared'

const Banner = ({ component: { text, background_text, image, logo, links_color } }) => (
  <section className={styles.section} logocolor={logo} linkscolor={links_color}>
    <div className={styles.image + ' background-image-cover-center'} style={{ backgroundImage: `url(${image})` }} />
    <div className={styles.container}>
      <LargeText text={text} />
      <BackgroundText className={styles.backgroundText} text={background_text} align="right" />
    </div>
    <GradientBackground transparentEdge="top" />
  </section>
)

export const query = graphql`
  fragment Banner on PagesJson {
    components {
      text
      background_text
      image
    }
  }
`

export default Banner
