import React, { useEffect, useState } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { Fade } from 'react-reveal'

import * as styles from './list.module.scss'
import { GradientBackground } from '../../../shared'

const List = ({ component: { title, text, logo, links_color } }) => {
  const [mode, setMode] = useState('grid')
  const [distanceFromStart, setDistanceFromStart] = useState(0)
  const [distanceFromEnd, setDistanceFromEnd] = useState(window.innerWidth)

  useEffect(() => {
    const list = document.getElementById('list-component')
    const changeScrollPos = () => {
      setDistanceFromStart(list.scrollLeft)
      setDistanceFromEnd(list.scrollWidth - (list.scrollLeft + window.innerWidth))
    }
    if (typeof window !== 'undefined') {
      if (list) {
        list.addEventListener('scroll', changeScrollPos)
      }
    }
    return () => list.removeEventListener('scroll', changeScrollPos)
  }, [])

  const handleSetListMode = () => {
    setMode('list')
  }

  const handleSetGridMode = () => {
    setMode('grid')
  }

  const handleScrollLeft = () => {
    const list = document.getElementById('list-component')
    list.scroll({ left: list.scrollLeft - window.innerWidth, behavior: 'smooth' })
  }

  const handleScrollRight = () => {
    const list = document.getElementById('list-component')
    list.scroll({ left: list.scrollLeft + window.innerWidth, behavior: 'smooth' })
  }

  const {
    capabilitiesJson: { capabilities }
  } = useStaticQuery(graphql`
    {
      capabilitiesJson(fields: { slug: { eq: "/capabilities/capabilities/" } }) {
        capabilities {
          image
          name
        }
      }
    }
  `)

  return (
    <section className={styles.section + `${mode === 'grid' ? ` ${styles.grid}` : ''}`} logocolor={logo} linkscolor={links_color}>
      <Fade up>
        <h2 className={styles.title}>{title}</h2>
      </Fade>
      <Fade up>
        <p className={styles.text}>
          {text.split('\n').map((line, i) => (
            <span key={i}>{line}</span>
          ))}
        </p>
      </Fade>
      <Fade up cascade>
        <div className={styles.viewToggles}>
          <button className={styles.button} onClick={handleSetListMode}>
            <div className={styles.list + `${mode === 'list' ? ` ${styles.active}` : ''}`} />
          </button>
          <button className={styles.button} onClick={handleSetGridMode}>
            <div className={styles.grid + `${mode === 'grid' ? ` ${styles.active}` : ''}`} />
          </button>
        </div>
      </Fade>
      <div className={styles.listContainer}>
        {capabilities && capabilities.length ? (
          <Fade up cascade>
            <div className={styles.list}>
              {capabilities.map((capability, i) => (
                <div key={i} className={styles.capability}>
                  <h3 className={styles.name}>{capability.name}</h3>
                </div>
              ))}
            </div>
          </Fade>
        ) : null}
      </div>
      <div className={styles.gridContainer}>
        {capabilities && capabilities.length ? (
          <>
            <div className={styles.arrows}>
              <div className={styles.arrowLeft}>
                <button className={styles.arrow + ` ${styles.left}` + `${distanceFromStart <= 5 ? ` ${styles.hide}` : ''}`} onClick={handleScrollLeft} />
              </div>
              <div className={styles.arrowRight}>
                <button className={styles.arrow + ` ${styles.right}` + `${distanceFromEnd <= 5 ? ` ${styles.hide}` : ''}`} onClick={handleScrollRight} />
              </div>
            </div>
            <Fade right cascade>
              <div className={styles.list} id="list-component">
                {capabilities.map((capability, i) => (
                  <div className={styles.capabilityContainer} key={i}>
                    <div className={styles.capability + ' background-image-cover-center'} style={{ backgroundImage: `url(${capability.image})` }} />
                    <h3 className={styles.name}>{capability.name}</h3>
                    <GradientBackground transparentEdge="top" />
                  </div>
                ))}
              </div>
            </Fade>
          </>
        ) : null}
      </div>
    </section>
  )
}

export const query = graphql`
  fragment List on PagesJson {
    components {
      title
      text
    }
  }
`

export default List
