import React, { useState } from 'react'
import { graphql } from 'gatsby'
import parse from 'html-react-parser'
import { Fade } from 'react-reveal'

import { Form } from '../../../shared'

import * as styles from './textTabs.module.scss'

const TextTabs = ({ component: { title, tabs, logo, links_color } }) => {
  const [active, setActive] = useState(0)

  const handleChange = e => {
    setActive(e.target.value)
  }
  return (
    <section className={styles.section} logocolor={logo} linkscolor={links_color}>
      <Fade up>
        <h2 className={styles.title}>{title}</h2>
      </Fade>
      <Fade right cascade>
        <div className={styles.tabs}>
          {tabs && tabs.length
            ? tabs.map((tab, i) => {
                const handleSetActive = () => {
                  setActive(i)
                }
                return (
                  <button className={styles.tab + `${active === i ? ` ${styles.active}` : ''}`} key={i} onClick={handleSetActive}>
                    <h3 className={styles.tabTitle}>{tab.title}</h3>
                  </button>
                )
              })
            : null}
        </div>
      </Fade>
      <select onChange={handleChange} className={styles.mobileTabs}>
        {tabs && tabs.length
          ? tabs.map((tab, i) => (
              <option key={i} value={i}>
                {tab.title}
              </option>
            ))
          : null}
      </select>
      <Fade up>
        <div className={styles.content}>
          {tabs && tabs.length && tabs[active] ? (
            <div className={styles.tabContent + `${tabs[active].form ? ` ${styles.form}` : ''}`}>
              <div className={styles.gradientTop} />
              <div className={styles.gradientBottom} />
              {tabs[active].text
                ? tabs[active].text.split('\n').map((line, i) => (
                    <p className={styles.paragraph} key={i}>
                      {line}
                    </p>
                  ))
                : null}
              {tabs[active].html ? parse(tabs[active].html) : null}
              <div className={styles.formContainer}>
                <div className={styles.background} />
                <div className={styles.container}>{tabs[active].form ? <Form form={tabs[active].form} /> : null}</div>
              </div>
            </div>
          ) : null}
        </div>
      </Fade>
    </section>
  )
}

export const query = graphql`
  fragment TextTabs on PagesJson {
    components {
      title
      tabs {
        title
        text
      }
    }
  }
`

export default TextTabs
