import React from 'react'
import { graphql } from 'gatsby'
import parse from 'html-react-parser'

import * as styles from './richText.module.scss'

const RichText = ({ component: { text } }) => {
  console.log('text', text)
  return (
    <section className={styles.section} logocolor="dark" linkscolor="dark">
      {text && parse(text)}
    </section>
  )
}

export const query = graphql`
  fragment RichText on PagesJson {
    components {
      text
    }
  }
`
export default RichText
