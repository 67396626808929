import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'

import * as styles from './copyright.module.scss'

const Copyright = () => {
  const {
    settingsJson: {
      footer: { copyright }
    }
  } = useStaticQuery(graphql`
    {
      settingsJson(fields: { slug: { eq: "/settings/settings/" } }) {
        footer {
          copyright
        }
      }
    }
  `)

  return (
    <section className={styles.section}>
      <p className={styles.copyright}>{copyright} | </p>
      <p className={styles.mobileCopyright}>{copyright}</p>
      <a href="http://fireupyourbrand.com" className={styles.link}>
        <p className={styles.text}>Website by Station8</p>
      </a>
    </section>
  )
}

export default Copyright
