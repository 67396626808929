import React from 'react'
import { Fade } from 'react-reveal'

import * as styles from './backgroundText.module.scss'

const BackgroundText = ({ text, align = 'left', color = 'green', className = '' }) => (
  <Fade right>
    <p className={styles.text + ` ${styles[align]}` + ` ${styles[color]}` + `${className ? ` ${className}` : ''}`}>
      {text.split('\n').map((line, i) => (
        <span key={i}>{line}</span>
      ))}
    </p>
  </Fade>
)

export default BackgroundText
