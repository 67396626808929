import React from 'react'
import { graphql } from 'gatsby'
import { Fade } from 'react-reveal'

import * as styles from './textColumns.module.scss'
import { Button } from '../../../shared'

const TextColumns = ({ component: { title, text, display_button, less_padding, button, logo, links_color } }) => (
  <section className={styles.section + `${less_padding ? ` ${styles.small}` : ''}`} logocolor={logo} linkscolor={links_color}>
    <Fade up>
      <div className={styles.titleContainer}>
        <h2 className={styles.title}>
          {title.split('\n').map((line, i) => (
            <span key={i}>{line}</span>
          ))}
        </h2>
      </div>
    </Fade>
    <div className={styles.textContainerParent}>
      <Fade up>
        <div className={styles.textContainer}>
          {text.split('\n').map((line, i) => (
            <p key={i} className={styles.paragraph}>
              {line}
            </p>
          ))}
        </div>
      </Fade>
    </div>
    <Button display_button={display_button} button={button} color="green" />
  </section>
)

export const query = graphql`
  fragment TextColumns on PagesJson {
    components {
      title
      text
      display_button
      less_padding
      button {
        label
        button_type
        function
        page
        url
      }
    }
  }
`

export default TextColumns
