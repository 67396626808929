import React from 'react'
import { graphql } from 'gatsby'
import { Fade } from 'react-reveal'

import * as styles from './cta.module.scss'
import { BackgroundText, Button } from '../../../shared'

const Cta = ({ component: { heading, text, display_button, button, image_overlay, logo, links_color } }) => (
  <section className={styles.section} logocolor={logo} linkscolor={links_color}>
    <div className={styles.content}>
      <div className={styles.body}>
        <Fade up>
          <div className={styles.titleContainer}>
            <h2 className={styles.title}>
              {heading.split('\n').map((line, i) => (
                <span key={i}>{line}</span>
              ))}
            </h2>
          </div>
        </Fade>
        <Fade up>
          <div className={styles.textContainer}>
            {text.split('\n').map((line, i) => (
              <p key={i} className={styles.paragraph}>
                {line}
              </p>
            ))}
          </div>
        </Fade>
      </div>
      <Button display_button={display_button} button={button} color="green" />
    </div>
    <div className={styles.image + ' background-image-cover-center'} style={{ backgroundImage: `url(${image_overlay.image})` }}>
      <BackgroundText className={styles.backgroundText} text={image_overlay.text} color="black" />
    </div>
  </section>
)

export const query = graphql`
  fragment Cta on PagesJson {
    components {
      heading
      text
      display_button
      button {
        label
        button_type
        function
        page
        url
      }
      image_overlay {
        image
        text
      }
    }
  }
`

export default Cta
