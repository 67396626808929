// extracted by mini-css-extract-plugin
export var backgroundImageContainCenter = "links-module--background-image-contain-center--1GOnq";
export var backgroundImageCoverCenter = "links-module--background-image-cover-center--2Mfmj";
export var section = "links-module--section--IizX1";
export var links = "links-module--links--2sNcg";
export var footer = "links-module--footer--3K55e";
export var link = "links-module--link--3dk1K";
export var active = "links-module--active--lmyvu";
export var label = "links-module--label--1Tmvu";
export var icon = "links-module--icon--3dFdU";
export var light = "links-module--light--231-4";
export var dark = "links-module--dark--JjOae";