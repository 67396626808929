import React from 'react'

import { Links, Logo } from './components'

import * as styles from './header.module.scss'

const Header = ({ openForm, linksColor, logoColor }) => (
  <header id="site-header" className={styles.header}>
    <Logo logoColor={logoColor} />
    <Links openForm={openForm} linksColor={linksColor} />
    <div className={styles.gradient} />
  </header>
)

export default Header
