import React from 'react'
import { graphql, Link, useStaticQuery } from 'gatsby'

const SiteLink = ({ activeClassName, className, children, page }) => {
  const {
    allPagesJson: { nodes }
  } = useStaticQuery(graphql`
    {
      allPagesJson {
        nodes {
          fields {
            link
            path
          }
        }
      }
    }
  `)
  const siteLink = nodes.map(node => ({ path: node.fields.path.substr(0, node.fields.path.length - 1) + '.json', link: node.fields.link })).find(node => page.page.includes(node.path))

  return siteLink && siteLink.link ? (
    <Link className={className} activeClassName={activeClassName} to={siteLink.link}>
      {children && children.length ? children : page.label}
    </Link>
  ) : (
    <p>Broken link</p>
  )
}

export default SiteLink
